import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import SEO from "../components/SEO"
import { Link } from "gatsby";

const smallScreen = '@media (max-width: 600px)';
const largeScreen = '@media (min-width: 601px)';

const Main = styled.main`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Container = styled.div`
  flex-grow: 1;
  display: inline-flex;
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;

  ${smallScreen} {
    flex-direction: column;
  }
`;

const Description = styled.div`
  flex-shrink: 0;
  font-size: 16px;
  line-height: 22px;

  ${largeScreen} {
    width: 66%;
  }
`;

const HideOnSmallScreen = styled.div`
  ${smallScreen} {
    display: none;
  }
`;

const FloatingPhoto = styled.div`
  ${smallScreen} {
    margin-top: 16px;
  }

  ${largeScreen} {
    margin-left: 16px;
  }
`;

const InlineLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    color: gray;
  }
`;

const IndexPage = () => {
  return (
    <Main>
      <SEO
        title="Hanie Mousavi - Architecture and Real Estate"
        description="I am an Architect and Real Estate Project Manager based in Zürich."
      />

      <Navbar />

      <Container>
        <Description>
          <HideOnSmallScreen>
            <p className="text-2xl">
              Hello! I'm Hanie, welcome to my website!
            </p>
          </HideOnSmallScreen>

          <p className="mt-2">
            I am an experienced Architect and Interior Designer based in Zurich, Switzerland
            {/* with a passion for giving value to space. */}
            {/* and creating places where people can truly feel at home. */}
          </p>

          <p className="mt-2">
            I hold a M.Sc. in Architecture and Built Environment from
            {' '}<InlineLink to="https://www.polimi.it/en/" target="_blank">Polytechnic University of Milan</InlineLink>,
            which ranks in the world's top 10 of universities in Architecture.
          </p>

          <p className="mt-2">
            Currently, I work as a <strong>Real Estate Project Manager</strong> at
            {' '}<InlineLink to="https://www.globus.ch/" target="_blank">Globus</InlineLink>,
            Switzerland's leading luxury department store.
          </p>

          {/* <p className="mt-2">
            During my studies, I was selected to work in an Architecture Studio in Milan, where I was put
            in charge of a complete renovation of a three-story 70s style Restaurant in one of the trendiest parts of Milan.
          </p> */}

          {/* <p className="mt-2">
            My duties were several:

            <ul className="list-disc pl-8">
              <li>I prepared moodboards using <strong>InDesign</strong>.</li>
              <li>I designed floor plans and custom-fitted furniture using <strong>AutoCAD</strong>.</li>
              <li>I produced high-quality 3D visualizations using <strong>SketchUp</strong> and <strong>V-Ray</strong>.</li>
              <li>I personally selected and ordered sample materials and finishings from suppliers.</li>
              <li>I had several meetings with the Clients, where I personally presented the various options.</li>
            </ul>
          </p> */}

          {/* <p className="mt-2">
            In 2022 I completed a UX / UI Design and User Research course to complement my professional skills. I am familiar with the steps of UX design, including:
            <ul className="list-disc pl-8">
              <li>Definition of the problem, ideation, brainstorming, user flow.</li>
              <li>How to emphasize with personas, wireframing, low-fidelity, high-fidelity, user-testing, prototyping.</li>
            </ul>
          </p> */}

          {/* <p className="mt-2">
            I am currently open to opportunities in UX / UI Design in Switzerland.
          </p> */}
        </Description>

        <FloatingPhoto>
          <StaticImage
            className="rounded-lg"
            src="../images/profile.jpg"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={400}
            height={500}
          />
        </FloatingPhoto>
      </Container>

      <Footer />
    </Main>
  )
}

export default IndexPage
